import React, { useState, useEffect } from "react";
import {
  PlusCircleIcon,
  BanknotesIcon,
  BuildingLibraryIcon,
  ArrowPathIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import {
  CreditCardIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/solid";
import Walletcard from "../../../components/walletcard/walletcard";
import { commaNumber, openNotificationWithIcon } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import Addmoney from "../../../components/addmoney/addmoney";
import moment from "moment";
import { message, Carousel } from "antd";
import {
  getLoanBalance,
  getWalletsDetails,
  getWalletTransaction,
} from "../../../api/wallet/wallets";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import ReactPullToRefresh from "react-pull-to-refresh";

import "swiper/swiper.scss";
import Viewaccount from "../../../components/viewaccount/viewaccount";

// const loadingWallet = true
// const transactions = [
//   {
//     amount: 100,
//     name: 'Full Repayment',
//     isTopUp: false
//   },
//   {
//     amount: 100,
//     name: 'Part Repayment',
//     isTopUp: false
//   },
//   {
//     amount: 1000,
//     name: 'Wallet Funding',
//     isTopUp: true
//   }
// ]

const Dashboardmain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [visibleAddMoney, setVisibleAddMoney] = useState(false);
  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [loadingTransactions, setloadingTransactions] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loadingloan, setLoadingloan] = useState(false);
  const [loans, setLoans] = useState<any>(null);
  const [viewWalletDetails, setViewWalletDetails] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    reloadWallet: state.user.reloadWallet,
  }));

  const { user, reloadWallet } = state;

  const paymentsNav = [
    {
      name: `<span>Airtime</span>`,
      img: "/img/vectors/airtime.svg",
      action: () => {
        navigate(`/dashboard/bill-payment/airtime`);
      },
    },
    {
      name: `<span>Data</span>`,
      img: "/img/vectors/data.svg",
      action: () => {
        navigate(`/dashboard/bill-payment/internetdata`);
      },
    },
    {
      name: `<span>Electricity</span>`,
      img: "/img/vectors/electricity.svg",
      action: () => {
        navigate(`/dashboard/bill-payment/electricity`);
      },
    },
    {
      name: `<span>TV</span>`,
      img: "/img/vectors/television.png",
      action: () => {
        navigate(`/dashboard/bill-payment/tv`);
      },
    },
    {
      name: `<span>Savings</span>`,
      img: "/img/vectors/piggy-bank.png",
			action: () => {
        navigate(`/dashboard/savings`);
      },
    },
    // {
    //   name: `<span>Marketplace</span>`,
    //   img: "/img/vectors/shop.svg",
    // },
    {
      name: `<span>Loans</span>`,
      img: "/img/vectors/loans.svg",
      action: () => {
        navigate(`/dashboard/loan`);
      },
    },
    {
      name: `<span>Investment</span>`,
      img: "/img/vectors/investment.png",
			action: () => {
        navigate(`/dashboard/investment`);
      },
    },

    {
      name: `<span>Payment</span>`,
      img: "/img/vectors/cards.svg",
      action: () => {
        navigate(`/dashboard/payment`);
      },
    },

    // {
    //   name: `<span>Business Tools</span>`,
    //   img: "/img/vectors/businesstools.svg",
    //   action: () => {
    //     navigate(`/dashboard/business-tools`);
    //   },
    // },

    // {
    //   name: `<span>Transport</span>`,
    //   img: "/img/vectors/transport.png",
    // },
    // {
    //   name: `<span>
    // 	Entertain...
    //     </span>`,
    //   img: "/img/vectors/popcorn.png",
    // },
    // {
    //   name: `<span>Donations</span>`,
    //   img: "/img/vectors/donation.png",
    // },
    // {
    //   name: `<span>Education</span>`,
    //   img: "/img/vectors/school.png",
    // },
  ];

  const updateViewWalletDetails = (value: boolean) => {
    setViewWalletDetails(value);
  };

  const closeViewWalletDetails = () => {
    setViewWalletDetails(false);
  };

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [...wallets];
        newWallets.push({
          ...data,
        });

        setWallets(data);

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  const getTransactions = async () => {
    setloadingTransactions(true);

    try {
      const res = await getWalletTransaction({});
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setTransactions(data);
        }
      } else {
        message.warning(`Get wallet transactions, ${res.data.message}`);
      }

      setloadingTransactions(false);
    } catch (error) {
      setloadingTransactions(false);
      message.error("Something went wrong: Get wallet transactions");
    }
  };

  const getLoan = async () => {
    setLoadingloan(true);
    try {
      const res = await getLoanBalance();

      const { status, data } = res.data;

      if (status === "success") {
        if (data?.repayment_amount !== 0) {
          setLoans(data);
        }
      } else {
        message.warning(`Get loan, ${res.data.message}`);
      }

      setLoadingloan(false);
    } catch (error) {
      setLoadingloan(false);
      message.error("Something went wrong: Get loan");
    }
  };

  useEffect(() => {
    getWallet();
    // getTransactions()
    // getLoan()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reloadWallet) {
      getWallet();
    }

    // eslint-disable-next-line
  }, [reloadWallet]);

  const closeAddMoney = () => {
    setVisibleAddMoney(false);
  };

  const getTransactionIcon = (name: string) => {
    switch (name) {
      case "Full repayment":
        return (
          <ClipboardDocumentCheckIcon
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "Part repayment":
        return (
          <ClipboardDocumentIcon
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "Wallet funding":
        return (
          <CreditCardIcon
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      default:
        return (
          <CreditCardIcon
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
    }
  };

  const handleRefresh = async () => {
    message.info("Refreshing wallets.");
    getWallet();
  };

  return (
    <div className="pb-40 overscroll-auto">
      {/* <ReactPullToRefresh onRefresh={handleRefresh}> */}
      {loadingWallets ? null : wallets.length === 0 ? null : (
        <div className="flex justify-end mb-2">
          {/* <p className="text-xs">Pull down to refresh wallets</p> */}

          <p
            className="flex pr-2 text-xs cursor-pointer"
            onClick={() => {
              message.info("Refreshing wallets.");
              getWallet();
            }}
          >
            <ArrowPathIcon className="w-4 h-4 text-appcolorblue" />
            <span className="pl-1">Refresh Balance</span>
          </p>
        </div>
      )}
      <div>
        {loadingWallets || loadingloan ? (
          <div>
            <Skeletonloader height={"10rem"} />
          </div>
        ) : (
          <>
            {wallets.length === 0 ? (
              <div
                className="h-48 p-4 mt-4 bg-no-repeat bg-cover rounded-lg cursor-pointer bg-appcolorblue bg-nowalletcard"
                onClick={() => {
                  navigate("/dashboard/create-wallet");
                }}
              >
                <div className="flex flex-col items-center justify-center mt-12 ">
                  <div className="w-11 h-11">
                    <PlusCircleIcon className="cursor-pointer text-appcolorwhite" />
                  </div>
                  <p className="cursor-pointer text-appcolorwhite font-poppins">
                    Create Wallet
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <Carousel>
                  {wallets.map((item, index) => (
                    <div key={`wallets-${index}-${item.id}`}>
                      <Walletcard
                        availableBalance={parseFloat(
                          item.available_balance
                        ).toFixed(2)}
                        name={`${user?.firstname}  ${user?.lastname}`}
                        updateViewWalletDetails={updateViewWalletDetails}
                        currency={item?.currency}
                      />
                    </div>
                  ))}

                  <div
                    className="h-48 p-4 bg-no-repeat bg-cover rounded-lg cursor-pointer bg-appcolorblue bg-nowalletcard"
                    onClick={() => {
                      navigate("/dashboard/create-wallet");
                    }}
                  >
                    <div className="flex flex-col items-center justify-center mt-12 ">
                      <div className="w-11 h-11">
                        <PlusCircleIcon className="cursor-pointer text-appcolorwhite" />
                      </div>
                      <p className="cursor-pointer text-appcolorwhite font-poppins">
                        Create Wallet
                      </p>
                    </div>
                  </div>

                  {/* <div>
                    <Walletcard
                      loans={loans}
                      availableBalance={
                        loans !== undefined ? loans?.repayment_amount : 0
                      }
                      name={`${user?.firstname}  ${user?.lastname}`}
                      isloanCard
                    />
                  </div> */}
                </Carousel>
              </div>
            )}
          </>
        )}
      </div>

      <div className="mt-8">
        <p className="text-lg font-medium b-0 font-poppins text-appcolorgrey">
          Smart and simple
        </p>

        <div className="grid grid-cols-4 gap-x-3 gap-y-8">
          {paymentsNav.map((item, index) => (
            <div
              key={`paynav-${index}-${item.name}`}
              className="flex flex-col items-center justify-center p-4 rounded-lg cursor-pointer bg-appcolorwhite drop-shadow-xl"
              onClick={() => {
                if (item?.action !== undefined) {
                  item?.action();
                } else {
                  openNotificationWithIcon(
                    "info",
                    "Info",
                    "Service not yet available for you. Preparing to serve you soon."
                  );
                }
              }}
            >
              <div className="flex justify-center w-10 h-10 mb-1">
                <img src={item.img} alt="loans" />
              </div>
              <p
                className="text-xs text-center font-poppins text-appcolorgrey"
                dangerouslySetInnerHTML={{ __html: item.name }}
              ></p>
            </div>
          ))}
        </div>
      </div>

      {/* <div className='mt-8'> */}
      {/* <div className='flex items-center justify-between'>
					<p className='text-lg font-medium b-0 font-poppins text-appcolorgrey'>
						Recent transactions
					</p>


					{
						transactions.length > 0 &&
						<p className='text-sm font-medium underline cursor-pointer b-0 font-poppins text-appcolorblue decoration-solid'
							onClick={() => {
								navigate('/dashboard/transactions')
							}}
						>
							View All
						</p>
					}
				</div> */}

      {/* <div className='pt-8 pb-16'>
					{
						loadingTransactions ?
							<>
								{
									[1, 2, 3].map((item) => (
										<div className='mb-4'
											key={`trans-preloader-${item}`}
										>
											<Skeletonloader
												height={'4rem'}
											/>
										</div>
									))
								}
							</>
							:
							<>
								{
									transactions.length === 0 ?
										<div className='flex flex-col items-center justify-center mt-2'>
											<div className='w-11 h-11'>
												<DocumentIcon className='cursor-pointer' />
											</div>
											<p className='cursor-pointer font-poppins'>
												No transactions
											</p>
											<p className='text-xs cursor-pointer font-poppins'>
												Let’s get started
											</p>
										</div>
										:
										<div>
											{
												transactions.slice(0, 5).map((item) => (
													<div key={item.id} className="flex items-start justify-between mb-5">
														<div className='grid items-start mr-2 max-w-[50%]'
															style={{
																gridTemplateColumns: '3rem auto'
															}}
														>
															<div className='h-10 p-2 mr-2 rounded-lg bg-appcolorlightgray'>
																{getTransactionIcon(item.remark)}
															</div>
															<div className='grow'>
																<p className='mb-0 text-sm font-semibold font-ibmplexsans text-appcolorblack'>
																	{item.remark} - {item.status}
																</p>
																<span className='text-xs'>
																	{moment(item.timestamp).format('lll')}
																</span>
															</div>
														</div>

														<div className='text-right'>
															<p className={`mb-0 text-sm font-semibold font-ibmplexsans ${item.type === 'credit' ? 'text-appcolorgreen' : 'text-appcolorred'}`}>
																₦ {commaNumber(item.amount)}
															</p>

															<span className='text-xs'>
																{item.type}
															</span>
														</div>
													</div>
												))
											}
										</div>
								}
							</>
					}
				</div> */}
      {/* </div> */}

      {visibleAddMoney && (
        <Addmoney visible={visibleAddMoney} closeModal={closeAddMoney} />
      )}

      {viewWalletDetails && (
        <Viewaccount
          visible={viewWalletDetails}
          closeModal={closeViewWalletDetails}
          accountDetails={
            wallets.length > 0 ? wallets[0]?.virtual_account : null
          }
        />
      )}
      {/* </ReactPullToRefresh> */}
    </div>
  );
};

export default Dashboardmain;

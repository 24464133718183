import { DocumentIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { getPaymentProductsManager } from "../../../api/payment/payment";
import { Button, message, Pagination, PaginationProps } from "antd";
import Transactionsfilter from "../transactions/transactionsfilter";
import { useNavigate } from "react-router-dom";

const BusinessTools = () => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const requestData = useMemo(() => {
    let realOtherFilters = {};
    if (otherFilters !== null) {
      realOtherFilters = otherFilters;
    }
    return {
      ...realOtherFilters,
      currency: "ngn",
      limit: otherFilters === null ? limit : 8,
      page: otherFilters === null ? currentPage : 1,
    };
  }, [limit, currentPage, otherFilters]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const fetchProducts = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getPaymentProductsManager({
        ...requestData,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null && data !== undefined) {
          setProducts(data);
          setTotal(parseInt(res.data.counts));
        } else {
          setProducts([]);
        }
      } else {
        message.warning(`Get products, ${res.data.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Get products");
    }
  }, [requestData]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const closeShowFilter = () => [setShowFilter(false)];

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Business Tools
          </h1>
        </div>

        <div className="pt-4 pb-16">
          {loading ? (
            <>
              {[1, 2, 3].map((item) => (
                <div className="mb-4" key={`trans-preloader-${item}`}>
                  <Skeletonloader height={"6rem"} />
                </div>
              ))}
            </>
          ) : (
            <>
              {products.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <div className="w-11 h-11">
                    <DocumentIcon className="cursor-pointer" />
                  </div>
                  <p className="cursor-pointer font-poppins">
                    No products found
                  </p>

                  <p className="text-center cursor-pointer font-poppins">
                    <span
                      className="text-appcolorblue"
                      onClick={() => {
                        setLimit(8);
                        setCurrentPage(1);
                        setOtherFilters(null);
                      }}
                    >
                      Refresh
                    </span>
                  </p>
                </div>
              ) : (
                <>
                  <div>
                    {/* <div className="flex justify-end mb-4">
                      <div className="flex items-center">
                        <span
                          className="text-sm font-bold text-appcolorblue"
                          onClick={() => {
                            if (!loading) {
                              setShowFilter(true);
                            }
                          }}
                        >
                          Filter
                        </span>
                        {otherFilters !== null && (
                          <span
                            className="pl-2 text-sm font-bold text-appcolorred"
                            onClick={() => {
                              if (!loading) {
                                setLimit(8);
                                setCurrentPage(1);
                                setOtherFilters(null);
                              }
                            }}
                          >
                            Clear Filter
                          </span>
                        )}
                      </div>
                    </div> */}

                    {products.map((item: any) => (
                      <div
                        className="p-4 mb-6 rounded-lg shadow-lg bg-appcolorlightgray"
                        key={item?.id}
                      >
                        <h1 className="mb-2">
                          Product Name:{" "}
                          <span className="font-bold">{item.name}</span>
                        </h1>

                        <div className="mb-8">
                          <p className="mb-1">Category: {item?.category}</p>
                        </div>

                        <Button
                          size="small"
                          className="custom-small-btn extra-small"
                          onClick={() => {
                            navigate(
                              `/dashboard/business-tools/orders/${item.id}`
                            );
                          }}
                        >
                          View
                        </Button>

                        {/* <div
                          className="flex items-center w-auto mt-4 cursor-pointer"
                          onClick={() => {
                            if (!deletingCard) {
                              runDeleteCard(item?.reference_code);
                            }
                          }}
                        >
                          {currentCard === item?.reference_code &&
                          deletingCard ? (
                            <LoadingOutlined className="text-red-500" />
                          ) : (
                            <DeleteOutlined className="text-red-500" />
                          )}
                          <p className="pl-1 text-red-500">
                            {currentCard === item?.reference_code &&
                            deletingCard
                              ? "Deleting "
                              : "Delete "}{" "}
                            Card
                          </p>
                        </div> */}
                      </div>
                    ))}

                    {products.length > 0 && (
                      <div className="mt-8 mb-4">
                        <Pagination
                          defaultCurrent={currentPage}
                          defaultPageSize={limit}
                          total={total}
                          onChange={onChange}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {showFilter && (
        <Transactionsfilter
          visible={showFilter}
          closeModal={closeShowFilter}
          setOtherFilters={setOtherFilters}
          isCards
        />
      )}
    </div>
  );
};

export default BusinessTools;

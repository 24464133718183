import { Button, Pagination, PaginationProps, Tag, message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IObjectKeysStringsOnly } from "../../../utils/helpers-interface";
import {
  getSavingsBalance,
  getSavingsDetails,
  getSavingsOffers,
  getSavingsType,
  submitSavingsRequest,
} from "../../../api/savings/savings";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  commaNumber,
  convertToSlug,
  openNotificationWithIcon,
  validateNumberAbove,
} from "../../../utils/helper";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import UploadRequestInfo from "../requestloan/uploadRequestInfo";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import Inputamount from "../../../components/inputamount/inputamount";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  ArrowPathIcon,
  ClipboardDocumentIcon,
  CreditCardIcon,
  DocumentIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import Walletcard from "../../../components/walletcard/walletcard";
import Transactionsfilter from "../transactions/transactionsfilter";

const Savings = () => {
  const navigate = useNavigate();

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    token: state.user.token,
  }));

  const { user, token } = state;

  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loanHistory, setSavingsHistory] = useState<any>([]);
  const [limit, setLimit] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [otherFilters, setOtherFilters] = useState<any>(null);
  const [showFilter, setShowFilter] = useState(false);

  const [savingsDetails, setSavingsDetails] = useState<any>([]);
  useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const requestData = useMemo(() => {
    let realOtherFilters = {};
    if (otherFilters !== null) {
      realOtherFilters = otherFilters;
    }
    return {
      ...realOtherFilters,
      currency: "ngn",
      type: "DIRECT",
      limit: otherFilters === null ? limit : 8,
      page: otherFilters === null ? currentPage : 1,
    };
  }, [limit, currentPage, otherFilters]);

  const closeShowFilter = () => [setShowFilter(false)];

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const fetchSavingsBalance = async () => {
    setLoading(true);

    try {
      const res = await getSavingsBalance({
        currency: "ngn",
      });
      const { status, data } = res.data;

      // setSavingsDetails([
      //   {
      //     repayment_amount: 0,
      //     total_collected: 0,
      //   },
      // ]);

      if (status === "success") {
        if (data !== null) {
          setSavingsDetails(data ?? []);
        }
      } else {
        message.warning(`Get Savings Balance, ${res.data.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Get Savings Balance");
    }
  };

  useEffect(() => {
    fetchSavingsBalance();
    // eslint-disable-next-line
  }, []);

  const fetchSavingsHistory = useCallback(async () => {
    setLoadingHistory(true);

    try {
      const res = await getSavingsDetails({
        ...requestData,
      });
      const { status, data } = res.data;

      // setSavingsDetails([
      //   {
      //     repayment_amount: 0,
      //     total_collected: 0,
      //   },
      // ]);

      if (status === "success") {
        if (data !== null) {
          setSavingsHistory(data ?? []);
        }
      } else {
        message.warning(`Get Savings History, ${res.data.message}`);
      }

      setLoadingHistory(false);
    } catch (error) {
      setLoadingHistory(false);
      message.error("Something went wrong: Get Savings History");
    }
  }, [requestData]);

  useEffect(() => {
    fetchSavingsHistory();
  }, [fetchSavingsHistory]);

  const getTransactionIcon = (name: string) => {
    switch (name) {
      case "Credit":
        return <CreditCardIcon />;
      case "Debit":
        return <CreditCardIcon />;
      default:
        return <ClipboardDocumentIcon />;
    }
  };

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-center justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Savings
          </h1>

          <p
            className="flex pr-2 mb-4 text-xs cursor-pointer"
            onClick={() => {
              message.info("Refreshing savings details.");
              fetchSavingsBalance();
              fetchSavingsHistory();
            }}
          >
            <ArrowPathIcon className="w-4 h-4 text-appcolorblue" />
            <span className="pl-1">Refresh</span>
          </p>
        </div>

        <div>
          {loading ? (
            <div>
              <Skeletonloader height={"10rem"} />
            </div>
          ) : (
            <>
              {savingsDetails.length === 0 ? (
                <div
                  className="h-40 p-2 mt-4 bg-no-repeat bg-cover rounded-lg cursor-pointer bg-appcolorblue bg-nowalletcard"
                  onClick={() => {
                    navigate("/dashboard/request-savings");
                  }}
                >
                  <div className="flex flex-col items-center justify-center mt-12">
                    <div className="w-11 h-11">
                      <PlusCircleIcon className="cursor-pointer text-appcolorwhite" />
                    </div>
                    <p className="cursor-pointer text-appcolorwhite font-poppins">
                      Create Savings
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <Walletcard
                    totalSaved={savingsDetails[0]?.total_saved}
                    totalSavedWithInterest={
                      savingsDetails[0]?.total_saved_with_interest
                    }
                    isSavingsCard
                  />
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex items-center justify-between mt-4">
          <Button
            className="mb-2"
            disabled={loading}
            onClick={() => {
              navigate("/dashboard/request-savings");
            }}
          >
            Create Savings
          </Button>
        </div>

        <div className="mt-8">
          <div className="flex items-center justify-between">
            <p className="text-lg font-medium b-0 font-poppins text-appcolorgrey">
              Savings History
            </p>

            <div className="flex items-center">
              <span
                className="text-sm font-bold text-appcolorblue"
                onClick={() => {
                  if (!loadingHistory) {
                    setShowFilter(true);
                  }
                }}
              >
                Filter
              </span>
              {otherFilters !== null && (
                <span
                  className="pl-2 text-sm font-bold text-appcolorred"
                  onClick={() => {
                    if (!loadingHistory) {
                      setLimit(20);
                      setCurrentPage(1);
                      setOtherFilters(null);
                    }
                  }}
                >
                  Clear Filter
                </span>
              )}
            </div>
          </div>

          {loadingHistory ? (
            <>
              {[1, 2, 3].map((item) => (
                <div className="mb-4" key={`trans-preloader-${item}`}>
                  <Skeletonloader height={"4rem"} />
                </div>
              ))}
            </>
          ) : (
            <>
              {loanHistory.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <div className="w-11 h-11">
                    <DocumentIcon className="cursor-pointer" />
                  </div>
                  <p className="text-center cursor-pointer font-poppins">
                    No savings history found.
                  </p>
                  {/* <p className="text-center cursor-pointer font-poppins">
                    Let’s get started
                  </p> */}
                </div>
              ) : (
                <div className="pb-28">
                  {loanHistory.map((item: any) => (
                    <div key={item.reference_code} className="relative">
                      <div className="relative flex items-start justify-between mb-5">
                        <div className="flex items-center">
                          <div className="pt-4">
                            <p className="mb-0 text-sm font-ibmplexsans text-appcolorblack">
                              Status:{" "}
                              <span
                                className={`font-semibold ${
                                  item?.status === "Failed" ||
                                  item?.status === "Overdue"
                                    ? "text-appcolorred"
                                    : item?.status === "Successful" ||
                                      item?.status === "Disbursed" ||
                                      item?.status === "Approved" ||
                                      item?.status === "Repaid"
                                    ? "text-appcolorgreen"
                                    : item?.status === "Processing"
                                    ? "text-appcoloryellow"
                                    : "text-appcolorblack"
                                }`}
                              >
                                {item.status}
                              </span>
                            </p>
                            <p className="text-xs">{item?.duration} days</p>
                            <span className="text-xs">
                              {moment(item?.added_timestamp).format("lll")}
                            </span>
                          </div>
                        </div>

                        <div className="pt-4 text-right">
                          <p
                            className={`mb-0 text-sm font-semibold font-ibmplexsans`}
                          >
                            ₦{" "}
                            {commaNumber(
                              parseFloat(item?.saved_amount).toFixed(2)
                            )}
                          </p>

                          {/* <span className="text-xs">{item.type}</span> */}
                        </div>
                      </div>
                      {/* {item?.status === "Overdue" ||
                        (item?.status === "Disbursed" && (
                          <div className="absolute bottom-[0.05rem] right-0">
                            <Tag
                              color="blue"
                              onClick={() => {
                                navigate(
                                  `/dashboard/repay/${item?.reference_code}`
                                );
                              }}
                            >
                              Repay
                            </Tag>
                          </div>
                        ))} */}
                    </div>
                  ))}

                  {loanHistory.length > 0 && (
                    <div className="mt-8 mb-4">
                      <Pagination
                        defaultCurrent={currentPage}
                        defaultPageSize={limit}
                        total={total}
                        onChange={onChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showFilter && (
        <Transactionsfilter
          visible={showFilter}
          closeModal={closeShowFilter}
          setOtherFilters={setOtherFilters}
          isCards
        />
      )}
    </div>
  );
};

export default Savings;

import { http } from ".."
import { addCardInterface, deleteCardInterface, getCardsInterface } from "./cards-interface"

export const addCard = (data: addCardInterface) => {
  return http.post('/service/linkcard/add', data)
}

export const getCards= (data: getCardsInterface) => {
  return http.post('/service/linkcard/details', data)
}

export const deleteCard= (data: deleteCardInterface) => {
  return http.post('/service/linkcard/remove', data)
}
import { http } from "..";
import { getPaymentProductsManagerPayload, getPaymentProductsOrdersPayload, getPaymentProductsPayload, sendInitiatePaymentPayload, sendPaymentRequestPayload } from "./payment-interface";

export const getPaymentCategories = () => {
  return http.post("/service/payments/category");
};



export const getPaymentProducts = (data: getPaymentProductsPayload) => {
  return http.post("/service/payments/products", data);
};


export const sendPaymentRequest = (data: sendPaymentRequestPayload) => {
  return http.post("/service/payments/request", data);
};


export const sendInitiatePayment = (data: sendInitiatePaymentPayload) => {
  return http.post("/service/payments/initiate", data);
};

export const getPaymentProductsManager = (data: getPaymentProductsManagerPayload) => {
  return http.post("/service/payments/manager/products", data);
};

export const getPaymentProductsOrders = (data: getPaymentProductsOrdersPayload) => {
  return http.post("/service/payments/manager/order", data);
};
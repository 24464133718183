import { Alert, Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  getPaymentCategories,
  getPaymentProducts,
  sendInitiatePayment,
  sendPaymentRequest,
} from "../../../api/payment/payment";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  commaNumber,
  convertToSlug,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
  validateNumberAbove,
} from "../../../utils/helper";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import UploadRequestInfo from "../requestloan/uploadRequestInfo";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import Inputamount from "../../../components/inputamount/inputamount";
import { IObjectKeysStringsOnly } from "../../../utils/helpers-interface";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    token: state.user.token,
  }));

  const { user, token } = state;

  const [amount, setAmount] = useState<number | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({
    category: "",
    product: "",
    amount: "",
  });
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<{
    min_amount: string;
    max_amount: string;
    id: string;
  } | null>(null);

  const [loading, setLoading] = useState(false);
  const [requestInfo, setRequestInfo] = useState<
    {
      name: string;
      type:
        | "select"
        | "text"
        | "number"
        | "alphanumeric"
        | "audio"
        | "video"
        | "pdf"
        | "image";
      option: string[];
      required: string;
    }[]
  >([]);

  const initiatePayment = async (referenceCode: string) => {
    try {
      setError(null);
      setLoading(true);

      const res = await sendInitiatePayment({
        reference_code: referenceCode,
      });

      const { status, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Payment", res.data.message);
        form.resetFields();
        navigate("/dashboard/payment-success");
      } else {
        // openNotificationWithIcon(
        //   "warning",
        //   "Submit Loan Offer",
        //   res.data.message
        // );
        setError(res?.data?.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Submit Payment");
    }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);

    const { amount, category, product, ...rest } = values;
    const request_info: {
      name: string;
      input: string;
    }[] = [];

    const resting: IObjectKeysStringsOnly = rest;

    Object.entries(resting).forEach(([key, value]) => {
      request_info.push({
        name: key?.split("-").join(" "),
        input: value,
      });
    });

    const newErrors = { ...errors };

    newErrors["amount"] = validateNumberAbove(amount, 0, "amount");

    if (selectedProduct?.max_amount !== selectedProduct?.min_amount) {
      if (Number(amount) < Number(selectedProduct?.min_amount)) {
        newErrors["amount"] = `Min amount is ₦${commaNumber(
          Number(selectedProduct?.min_amount)
        )}`;
      }
      if (Number(amount) > Number(selectedProduct?.max_amount)) {
        newErrors["amount"] = `Max amount is ₦${commaNumber(
          Number(selectedProduct?.max_amount)
        )}`;
      }
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {

        const res = await sendPaymentRequest({
          currency: "ngn",
          request_info,
          id: selectedProduct?.id as string,
          amount,
          narration: request_info
            .map((item) => `${item.name}=${item.input}`)
            .join("-"),
        });


        const { status, data } = res.data;

        if (status === "success") {
          await initiatePayment(data?.reference_code);
          // openNotificationWithIcon(
          //   "success",
          //   "Submit Payment Request",
          //   res.data.message
          // );
        } else {
          // openNotificationWithIcon(
          //   "warning",
          //   "Submit Loan Offer",
          //   res.data.message
          // );
          setError(res?.data?.message);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        // setLoadingServices(false);
        message.error("Something went wrong: Submit Payment Request");
      }
    } else {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((item: any) => {
      setErrors((prevState) => ({
        ...prevState,
        [item.name[0]]: item.errors.length ? item.errors[0] : "",
      }));
    });
  };

  const fetchProducts = async (category: string) => {
    setLoadingProducts(true);

    try {
      const res = await getPaymentProducts({
        currency: "ngn",
        category,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setProducts(data);
        }
      } else {
        message.warning(`Get Payment Products, ${res.data.message}`);
      }

      setLoadingProducts(false);
    } catch (error) {
      setLoadingProducts(false);
      message.error("Something went wrong: Get Payment Products");
    }
  };

  const resetFormerRequestInfoFieldValues = () => {
    let getFormFieldNames = requestInfo.map((item) => convertToSlug(item.name));
    getFormFieldNames.forEach((item) => {
      form.setFieldValue(item, undefined);
    });
  };

  const updateField = (name: string, value?: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "category") {
      form.setFieldValue("product", undefined);
      fetchProducts(value as string);
      setProducts([]);
      resetFormerRequestInfoFieldValues();
      setRequestInfo([]);
    }

    if (name === "product") {
      resetFormerRequestInfoFieldValues();
      const filterRequestInfo: any[] = products.filter(
        (item: any) => item?.id === value
      );
      setSelectedProduct(filterRequestInfo[0]);
      setRequestInfo(filterRequestInfo[0]?.request_info);
    }
  };

  const fetchCategoriesTypes = async () => {
    setLoadingCategories(true);

    try {
      const res = await getPaymentCategories();
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setCategories(data);
        }
      } else {
        message.warning(`Get Payment Categories, ${res.data.message}`);
      }

      setLoadingCategories(false);
    } catch (error) {
      setLoadingCategories(false);
      message.error("Something went wrong: Get Payment Categories");
    }
  };

  useEffect(() => {
    fetchCategoriesTypes();
    // eslint-disable-next-line
  }, []);

  const constructFormInputs = () => {
    return (
      <>
        {requestInfo.map((item, index) => (
          <Form.Item
            key={index}
            label={item.name}
            name={convertToSlug(item.name)}
            rules={[
              {
                required: item.required === "Yes",
                message: `${
                  item?.type === "select"
                    ? "Select"
                    : item?.type === "alphanumeric" ||
                      item?.type === "number" ||
                      item?.type === "text"
                    ? "Input"
                    : "Upload"
                } is required`,
              },
            ]}
          >
            {item.type === "select" && (
              <Inputselect
                placeHolder={`Select ${item.name}`}
                updateField={updateField}
                name={convertToSlug(item.name)}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={item.option}
                optionHasValue
                loading={false}
                showSearch={false}
              />
            )}
            {item.type === "text" || item.type === "alphanumeric" ? (
              <Input
                name={convertToSlug(item.name)}
                placeholder={` ${item.name}`}
                onChange={(e: any) => {
                  updateField(convertToSlug(item.name), e.target.value);
                }}
              />
            ) : null}

            {item.type === "number" && (
              <Inputwithaddon
                updateField={updateField}
                name={convertToSlug(item.name)}
                placeHolder="8000000000"
                addonBefore="+234"
              />
            )}

            {item.type === "pdf" ||
            item.type === "image" ||
            item.type === "audio" ||
            item.type === "video" ? (
              <UploadRequestInfo
                token={token}
                type={item.type}
                name={convertToSlug(item.name)}
                submitting={loading}
                updateField={updateField}
                uploadName={item.name}
                setUploading={setUploading}
              />
            ) : null}
          </Form.Item>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (selectedProduct !== null) {
      if (selectedProduct?.min_amount === selectedProduct?.max_amount) {
        form.setFieldValue("amount", selectedProduct?.max_amount);
        setAmount(Number(selectedProduct?.max_amount));
      }
    }
  }, [selectedProduct, form.getFieldValue("amount")]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
          Payments
        </h1>

        <div>
          {error != null && (
            <div className="mb-3">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              validateStatus={errors?.category.length > 0 ? "error" : undefined}
              help={errors?.category.length > 0 ? errors.category : undefined}
              label="Payment Type"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Payment Type is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Payment Type"
                updateField={updateField}
                name={"category"}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={categories}
                alternativeValue={"name"}
                selectOptionLabel="name"
                loading={loadingCategories}
                showSearch={true}
              />
            </Form.Item>

            <Form.Item
              validateStatus={errors?.product.length > 0 ? "error" : undefined}
              help={errors?.product.length > 0 ? errors.product : undefined}
              label="Product Name | Collection"
              name="product"
              rules={[
                {
                  required: true,
                  message: "Product name is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Product Name | Collection"
                updateField={updateField}
                name={"product"}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={products}
                alternativeValue={"id"}
                selectOptionLabel="name"
                loading={loadingProducts}
                showSearch={true}
              />
            </Form.Item>

            {selectedProduct !== null && (
              <Form.Item
                validateStatus={errors?.amount.length > 0 ? "error" : undefined}
                help={errors?.amount.length > 0 ? errors.amount : undefined}
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                ]}
              >
                <Inputamount
                  name="amount"
                  updateField={updateField}
                  placeHolder="Enter amount"
                  currency={"₦"}
                  amountValue={
                    selectedProduct?.min_amount === selectedProduct?.max_amount
                      ? amount?.toString()
                      : undefined
                  }
                  disabled={
                    selectedProduct?.min_amount === selectedProduct?.max_amount
                  }
                />
              </Form.Item>
            )}

            {constructFormInputs()}

            <Form.Item>
              <div className="flex flex-col items-center justify-center mx-auto mt-0 mb-40">
                <Button
                  htmlType="submit"
                  className="mb-2"
                  disabled={
                    loading || loadingCategories || uploading || loadingProducts
                  }
                  loading={loading}
                >
                  Make Payment
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Payment;

import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../store/userSlice/userSlice";

const KYCsuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Congratulations👋
      </h1>

      <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey  lg:w-3/12">
        You have successfully completed your KYC process, now lets customize
        your profile and get you ready to apply for a loan.
      </p>

      <div className="flex justify-center mb-14">
        <img src="/img/vectors/happyman.svg" alt="happyman" />
      </div>

      <div className="flex flex-col items-center justify-center w-9/12 mx-auto mb-8  lg:w-3/12">
        <Button
          className="mb-2"
          onClick={() => {
            dispatch(logoutUser());
            navigate("/signin");
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default KYCsuccess;

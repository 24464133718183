import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { formValuesInterface } from "./confirmotp-interface";
import { getOTPCode, submitOTP } from "../../../api/base/base";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { openNotificationWithIcon } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { logoutUser, updateUser } from "../../../store/userSlice/userSlice";

const Confirmotp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendingPhone, setResendingPhone] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verifingEmail, setVerifingEmail] = useState(false);
  const [verifyingPhone, setVerifyingPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    emailOTP: "",
    phoneOTP: "",
  });

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const onFinishEmail = async (values: formValuesInterface) => {
    const { otp } = values;

    setLoading(false);
    setVerifingEmail(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await submitOTP(
          {
            otp,
            mode: "verify_otp",
          },
          "email"
        );

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Verify Email", message);
          setEmailVerified(true);
          dispatch(
            updateUser({
              user: {
                ...user,
                kyc: {
                  ...user?.kyc,
                  auth: {
                    ...user?.kyc?.auth,
                    email_verified: "Yes",
                  },
                },
              },
            })
          );
        } else {
          openNotificationWithIcon("error", "Verify Email", message);
        }

        setLoading(false);
        setVerifingEmail(false);
      } catch (error: any) {
        setLoading(false);
        setVerifingEmail(false);
        message.error("Something went wrong: Verify email");
      }
    } else {
      setLoading(false);
      setVerifingEmail(false);
    }
  };

  const onFinishPhone = async (values: formValuesInterface) => {
    const { otp } = values;

    setLoading(false);
    setVerifyingPhone(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await submitOTP(
          {
            otp,
            mode: "verify_otp",
          },
          "phone"
        );

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Verify Phone", message);
          setPhoneVerified(true);
          dispatch(
            updateUser({
              user: {
                ...user,
                kyc: {
                  ...user?.kyc,
                  auth: {
                    ...user?.kyc?.auth,
                    phone_verified: "Yes",
                  },
                },
              },
            })
          );
        } else {
          openNotificationWithIcon("error", "Verify Phone", message);
        }

        setLoading(false);
        setVerifyingPhone(false);
      } catch (error: any) {
        setLoading(false);
        setVerifyingPhone(false);
        message.error("Something went wrong: Verify Phone");
      }
    } else {
      setLoading(false);
    }
  };

  const resendOTP = async (resendType: "email" | "phone") => {
    if (resendType === "email") {
      setResendingEmail(true);
      try {
        const res = await getOTPCode(
          {
            mode: "send_otp",
          },
          resendType
        );

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            "Request Email Verification",
            message
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Request Email Verification",
            message
          );
        }

        setResendingEmail(false);
      } catch (error) {
        setResendingEmail(false);
        message.error("Something went wrong, Request email otp");
      }
    }

    if (resendType === "phone") {
      setResendingPhone(true);
      try {
        const res = await getOTPCode(
          {
            mode: "send_otp",
          },
          resendType
        );

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            "Request Phone Verification",
            message
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Request Phone Verification",
            message
          );
        }

        setResendingPhone(false);
      } catch (error) {
        setResendingPhone(false);
        message.error("Something went wrong, Request phone otp");
      }
    }
  };

  useEffect(() => {
    if (user !== null) {
      let kyc = user?.kyc;

      if (kyc?.auth?.phone_verified !== undefined) {
        setShowPhone(true);
        if (kyc?.auth?.phone_verified === "No") {
          setPhoneVerified(false);
        } else {
          setPhoneVerified(true);
        }
      } else {
        setPhoneVerified(true);
      }

      if (kyc?.auth?.email_verified !== undefined) {
        setShowEmail(true);
        if (kyc?.auth?.email_verified === "No") {
          setEmailVerified(false);
        } else {
          setEmailVerified(true);
        }
      } else {
        setEmailVerified(true);
      }

      // for (let index = 0; index < kyc.length; index++) {
      //   let element = kyc[index];
      //   Object.entries(element).forEach(([key, value]) => {
      //     if (key === "email") {
      //       if (value === "Pending") {
      //         setEmailVerified(false);
      //       } else {
      //         setEmailVerified(true);
      //       }
      //     }

      //     if (key === "phone") {
      //       if (value === "Pending") {
      //         setPhoneVerified(false);
      //       } else {
      //         setPhoneVerified(true);
      //       }
      //     }
      //   });
      // }
    } else {
      navigate("/signin");
    }

    // eslint-disable-next-line
  }, [user]);

  const checkValidationDone = () => {
    let kyc = user?.kyc;

    if (
      kyc?.auth?.phone_verified === "Yes" &&
      kyc?.auth?.email_verified === "Yes"
    ) {
      return "Email and Phone Verification Done";
    }

    if (
      kyc?.auth?.phone_verified === "Yes" &&
      (kyc?.auth?.email_verified === undefined ||
        kyc?.auth?.email_verified === "No")
    ) {
      return "Phone Verification Done";
    }

    if (
      kyc?.auth?.email_verified === "Yes" &&
      (kyc?.auth?.phone_verified === undefined ||
        kyc?.auth?.phone_verified === "No")
    ) {
      return "Email Verification Done";
    }
  };

  return (
    <div className="mt-4 confirmotp-page">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Confirm OTP
      </h1>

      <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey lg:w-3/12">
        A One-Time Passcode has been sent to your registered phone number or
        email as the case may be.
      </p>

      {showEmail && (
        <div className="flex justify-center w-11/12 mx-auto lg:w-3/12">
          <Form className="w-full" onFinish={onFinishEmail} layout="vertical">
            <Form.Item
              label={
                emailVerified
                  ? "EMAIL VERIFIED"
                  : "Enter OTP sent to your Email"
              }
              name="otp"
              rules={[
                {
                  required: true,
                  message: "OTP is required",
                },
              ]}
              hasFeedback={emailVerified || verifingEmail}
              validateStatus={
                emailVerified
                  ? "success"
                  : verifingEmail
                  ? "validating"
                  : undefined
              }
            >
              <Input
                placeholder={emailVerified ? "EMAIL VERIFIED" : "Enter OTP"}
                disabled={resendingEmail || emailVerified || verifingEmail}
                value={emailVerified ? "EMAIL VERIFIED" : undefined}
              />
            </Form.Item>

            {!emailVerified && (
              <Form.Item>
                <Row justify="space-between">
                  <Col>
                    <Button
                      className="purple-skel"
                      size="small"
                      onClick={() => {
                        resendOTP("email");
                      }}
                      loading={resendingEmail}
                      disabled={
                        resendingEmail || emailVerified || verifingEmail
                      }
                    >
                     Request OTP
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="purple-skel"
                      size="small"
                      htmlType="submit"
                      loading={verifingEmail}
                      disabled={
                        resendingEmail || emailVerified || verifingEmail
                      }
                    >
                      Validate
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            )}
          </Form>
        </div>
      )}

      {showPhone && (
        <div className="flex justify-center w-11/12 mx-auto lg:w-3/12">
          <Form className="w-full" onFinish={onFinishPhone} layout="vertical">
            <Form.Item
              label={
                phoneVerified
                  ? "PHONE VERIFIED"
                  : "Enter OTP sent to your Phone"
              }
              name="otp"
              rules={[
                {
                  required: true,
                  message: "OTP is required",
                },
              ]}
              hasFeedback={phoneVerified || verifyingPhone}
              validateStatus={
                phoneVerified
                  ? "success"
                  : verifyingPhone
                  ? "validating"
                  : undefined
              }
            >
              <Input
                placeholder={phoneVerified ? "PHONE VERIFIED" : "Enter OTP"}
                disabled={resendingPhone || phoneVerified || verifyingPhone}
                value={phoneVerified ? "PHONE VERIFIED" : undefined}
              />
            </Form.Item>

            {!phoneVerified && (
              <Form.Item>
                <Row justify="space-between">
                  <Col>
                    <Button
                      className="purple-skel"
                      size="small"
                      onClick={() => {
                        resendOTP("phone");
                      }}
                      loading={resendingPhone}
                      disabled={
                        resendingPhone || phoneVerified || verifyingPhone
                      }
                    >
                      Request
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="purple-skel"
                      size="small"
                      htmlType="submit"
                      loading={verifyingPhone}
                      disabled={
                        resendingPhone || phoneVerified || verifyingPhone
                      }
                    >
                      Validate
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            )}
          </Form>
        </div>
      )}

      <div className="w-11/12 mx-auto lg:w-3/12">
        <span className="mb-4 text-sm font-normal text-center text-appcolorgrey font-poppins">
          By tapping on 'Continue' , you agree to the Terms of Use and Privacy
          Policy
        </span>

        <Button
          className="mt-4 mb-2"
          disabled={loading || !emailVerified || !phoneVerified}
          loading={loading}
          onClick={() => {
            openNotificationWithIcon("success", `${checkValidationDone()}`);
            dispatch(logoutUser());
            navigate("/onboard-success");
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default Confirmotp;
